import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./SurveyModal.scss";
import Modal from "../__common/Modal/Modal";
import { Checkbox } from "../__common/_controls/Checkbox/Checkbox";
import { Button } from "../__common/_controls";
import { Typography } from "../__common/Typography/Typography";
import TextArea from "../__common/TextArea/TextArea";
import { question1 } from "../../data/surveyQuestions";
import { useUserHooks } from "../../hooks/user-hooks";
import { ApplicationState } from "../../store";
import { UserState } from "../../store/reducers/user-reducer";
import { useSelector } from "react-redux";
import { SHOW_SURVEY, USER_SURVEY_SKIP } from "../../constants/storage";
import { Cookie } from "../../services/storage";
interface Props {
  isOpen: boolean;
  onHide?: () => void;
}

export const SurveyModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const { submitUserSurvey } = useUserHooks();

  const [surveyData, setSurveyData] = useState({
    answer_1: "Very Satisfied",
    comments: "",
  });

  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const handleCheckboxOnChange = (questionNum: number, value: any) => {
    switch (questionNum) {
      case 1:
        setSurveyData({ ...surveyData, answer_1: value });
        break;
    }
  };

  useEffect(() => {
    setModalOpen(props.isOpen);
    if (props.isOpen) {
      Cookie.remove(SHOW_SURVEY);
    }
  }, [props.isOpen]);

  const handleOnClose = () => {
    const userLogin = userState.userData.loginId;
    const skippedUser = Cookie.get(USER_SURVEY_SKIP) || "[]";
    const skippedUserArr = JSON.parse(skippedUser);

    if (skippedUserArr.length === 0) {
      skippedUserArr.push(userLogin);
    } else {
      const userDidSkip =
        skippedUserArr.findIndex((item: string) => userLogin === item) > -1;
      if (!userDidSkip) skippedUserArr.push(userLogin);
    }
    Cookie.set(USER_SURVEY_SKIP, JSON.stringify(skippedUserArr));
    setModalOpen(false);
    Cookie.remove(SHOW_SURVEY);
  };

  const handleSurveySubmit = () => {
    submitUserSurvey(surveyData);
    setModalOpen(false);
    Cookie.remove(SHOW_SURVEY);
  };

  return (
    <Modal
      testId="survey-modal"
      show={isModalOpen}
      wrapperClassName="survey-modal"
      dir="ltr"
    >
      <Modal.Header
        headertestid="survey-modal-close-button"
        onHide={() => handleOnClose()}
      >
        <Modal.Title>{t("PRIVACY_SURVEY_TITLE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="survey-modal__top-content">
          <Typography variant="body2">{t("PRIVACY_SURVEY_DESC")}</Typography>
        </div>
        <div className="survey-modal__question">
          <Typography variant="title3" className="survey-modal__question__text">
            {t("PRIVACY_SURVEY_Q1")}
          </Typography>
          <div className="survey-modal__form-container">
            {question1.map((e, index) => (
              <Checkbox
                testId="survey-checkbox"
                type="withImg"
                id={e.id}
                key={e.id}
                title={e.title}
                name={e.name}
                checked={surveyData.answer_1 === e.name}
                svgIcon={e.svg}
                onChange={() => handleCheckboxOnChange(1, e.name)}
              />
            ))}
          </div>
        </div>
        <TextArea
          testId="survey-text-area"
          className="survey-modal__textarea"
          placeHolder={t("PRIVACY_SURVEY_FEEDBACK_PLACEHOLDER")}
          value={surveyData.comments}
          maxChars={500}
          onChange={(e) => {
            setSurveyData({ ...surveyData, comments: e.target.value });
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-container">
          <Button
            testId="submit-survey-button"
            title={t("PRIVACY_SURVEY_BTN_SEND")}
            onClick={handleSurveySubmit}
            disabled={
              userState.userSurvey.isSubmittingSurvey ||
              surveyData.answer_1 === ""
            }
          ></Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

import { ROUTES } from "../configs/routes";
import { RCODES } from "../constants/rcodes";

// NOTE: not used atm
export const handleAPIErrorBasedOnRcode = (rcode: number) => {
  switch (rcode) {
    // temporarily remove the error 401 handler since the /profile API is still being call after successful logout,
    // due to the delay removal of SC_ATKN
    // case RCODES.UNAUTHORIZED:
    //   window.location.href = ROUTES.LOGIN;
    //   break;
    case RCODES.BAD_REQUEST:
      window.location.href = ROUTES.ERROR_400;
      break;
    case RCODES.INTERNAL_SERVER_ERROR:
    case RCODES.DATABASE_ERROR:
    case RCODES.DATABASE_ERROR2:
      window.location.href = ROUTES.ERROR_500;
      break;
  }
};

export const handleAPIErrorBasedOnHTTPstatus = (statusCode: number) => {
  switch (statusCode) {
    case 400:
      window.location.href = ROUTES.ERROR_400;
      break;
    // case 401:
    //   window.location.href = ROUTES.LOGIN;
    //   break;
    case 403:
      window.location.href = ROUTES.ERROR_403;
      break;
    case 404:
      window.location.href = ROUTES.ERROR_404;
      break;
    case 500:
      window.location.href = ROUTES.ERROR_500;
      break;
    case 502:
      window.location.href = ROUTES.ERROR_502;
      break;
    case 503:
      window.location.href = ROUTES.ERROR_503;
      break;
  }
};

export const RCODE_ERROR_PAGES = [
  RCODES.BAD_REQUEST,
  RCODES.FORBIDDEN_REQUEST,
  RCODES.NOT_FOUND,
  RCODES.INTERNAL_SERVER_ERROR,
  RCODES.DATABASE_ERROR,
  RCODES.DATABASE_ERROR2,
];

import { BaseEntityRes } from "./base";

export enum LOGIN_ID_TYPE {
  EMAIL = "email",
  PHONE = "phone",
}

export enum REQUEST_TYPE {
  DOWNLOAD = "A",
  DELETE = "E",
  UNSUBSCRIBE = "U",
}
export interface User {
  birthDate: string;
  countryCode: string;
  joinedDate?: string;
  loginId: string;
  loginIdType: string;
  mail: string;
  name: string;
  validatedEmail: string;
  profileImageUrl?: string;
  userType?: "DIGITAL_LEGACY" | "SAMSUNG_ACCOUNT";
}

export interface UserRegion {
  isFromEurope?: boolean;
  isFromUS?: boolean;
  isRestricted?: boolean;
  isSAFromAmerica?: boolean;
  isSAFromUSA?: boolean;
  isSAFromEurope?: boolean;
}

export interface UserSurvey {
  isFetchingSurveyResponse: boolean;
  isSubmittingSurvey: boolean;
  hasAnsweredSurvey: boolean;
}

export interface Survey {
  survey_id?: number;
  answer_1: string;
  comments?: string;
}

export interface UserEmailLoginInfo {
  fname: string;
  lname: string;
  email: string;
}

export interface SurveyEntityRes extends BaseEntityRes {}

import { SVG_ICONS } from "../components/__common/Svg/Svg";

export const question1 = [
  {
    id: "question1-option-1",
    title: "",
    name: "Very Dissatisfied",
    svg: SVG_ICONS.SURVEY_VERY_DISSATISFIED,
  },
  {
    id: "question1-option-2",
    title: "",
    name: "Dissatisfied",
    svg: SVG_ICONS.SURVEY_DISSATISFIED,
  },
  {
    id: "question1-option-3",
    title: "",
    name: "Satisfied",
    svg: SVG_ICONS.SURVEY_SATISFIED,
  },
  {
    id: "question1-option-4",
    title: "",
    name: "Very Satisfied",
    svg: SVG_ICONS.SURVEY_VERY_SATISFIED,
  },
];

export const question2 = [
  {
    id: "question2-option-yes",
    title: "Yes",
    name: "Y",
  },
  {
    id: "question2-option-no",
    title: "No",
    name: "N",
  },
];

export const question3 = [
  {
    id: "question3-option-yes",
    title: "Yes",
    name: "Y",
  },
  {
    id: "question3-option-no",
    title: "No",
    name: "N",
  },
];

import React from "react";
import classNames from "classnames";

import "./TextArea.scss";

interface Props {
  placeHolder?: string;
  className?: string;
  value?: string;
  maxChars?: number;
  onChange?: (e: any) => void;
  testId?: string;
}

const TextArea: React.FC<Props> = (props) => {
  const {
    placeHolder,
    onChange,
    className,
    maxChars = 500,
    value = "",
    testId,
  } = props;

  const wrapClass = classNames("textarea-wrapper", className);

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e);
    }
  };
  return (
    <textarea
      className={wrapClass}
      placeholder={placeHolder}
      onChange={(e) => handleOnChange(e)}
      value={value}
      maxLength={maxChars}
      data-testid={testId}
    />
  );
};

export default TextArea;

import { API_BASE_URL, API_ENDPOINTS } from "../constants/api";

export const useAdssoLoginGuard = () => {
  const redirect_uri = `${API_BASE_URL}${API_ENDPOINTS.ADSSO_LOGIN_GUARD}`;

  const ssoLoginRedirect = () => {
    const nonce =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    window.location.href = `${process.env.REACT_APP_AD_SSO_BASE_URL}${process.env.REACT_APP_AD_SSO_AUTHORIZE_URL}?client_id=${process.env.REACT_APP_AD_SSO_CLIENT_ID}&scope=openid+profile&response_type=code+id_token&response_mode=form_post&redirect_uri=${redirect_uri}&nonce=${nonce}`;
  };

  return { ssoLoginRedirect };
};

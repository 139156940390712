import { useDispatch, useSelector } from "react-redux";

import {
  API_SA_BASE_URL,
  API_ENDPOINTS,
  API_BASE_URL,
  API_CHINA_SA_BASE_URL,
  API_SA_V3_BASE_URL,
} from "../constants/api";
import {
  ACR_KEY,
  ACR_VALUES,
  CLIENT_ID,
  DL_CLIENT_ID,
} from "../constants/appConstants";
import {
  COOKIE_SC_ATKN,
  EMAIL_LOGIN_VERIFICATION_RESULT,
  REDIRECT,
  REDIRECT_URI,
  RESTRICTED,
  USER_DATA_NAMESPACE,
  USER_SESSION_EXPIRED_TIME,
  USER_SESSION_ID,
} from "../constants/storage";
import { Cookie, LocalStorage } from "../services/storage";
import { actionLogout } from "../store/actions/user-actions";
import { SignInEntity, SignOutEntity, ConfirmPassEntity } from "../types/auth";
import { generateToken } from "../utils/UtilsTokenGenerator";
import { REQUEST_TYPE } from "../types/user";
import { UtilsRequest } from "../utils/UtilsRequest";
import config from "../constants/serverConfig";
import { ROUTES } from "../configs/routes";
import { ApplicationState } from "../store";
import { LayoutState } from "../store/reducers/layout-reducer";
import triggerGAEvent from "../utils/UtilsTriggerGAEvent";
import {
  EVENT_CATEGORY_HEADER,
  EVENT_HEADER_ACTION_LOGIN,
  EVENT_HEADER_ACTION_LOGIN_CHINA,
  EVENT_HEADER_ACTION_LOGOUT,
} from "../constants/analytics";
import { ServiceEntity } from "../types/services";
import { UserState } from "../store/reducers/user-reducer";
import { GEOIP_CHINA_COUNTRY_CODE } from "../constants/locale";
import { getDomain } from "../utils/UtilsBrowser";
import { useUserHooks } from "./user-hooks";
import { URLS } from "../constants/urls";
// import { useHistory } from "react-router";

export const useAuth = () => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  const { isDigitalLegacy } = useUserHooks();

  const login = (redirectURL?: string) => {
    const appState = generateToken(32);
    const SA_BASE_URL =
      userState.userCountryCode === GEOIP_CHINA_COUNTRY_CODE
        ? API_CHINA_SA_BASE_URL
        : API_SA_BASE_URL;
    const EVENT_HEADER_LOGIN =
      userState.userCountryCode === GEOIP_CHINA_COUNTRY_CODE
        ? EVENT_HEADER_ACTION_LOGIN_CHINA
        : EVENT_HEADER_ACTION_LOGIN;

    const authUrl = `${API_BASE_URL}${API_ENDPOINTS.AUTH_SIGNIN_URL}?returnUrl=${API_BASE_URL}${redirectURL}?success=1&appId=${CLIENT_ID}`;

    LocalStorage.remove(USER_SESSION_EXPIRED_TIME);

    const paramsEntity: SignInEntity = {
      response_type: "code",
      client_id: CLIENT_ID,
      locale: layoutState.languageCode,
      countryCode: layoutState.countryCode,
      redirect_uri: authUrl,
      state: appState,
      goBackURL: API_BASE_URL,
      acr_values: ACR_VALUES,
      acr_key: ACR_KEY,
    };

    const params = new URLSearchParams({ ...paramsEntity });

    triggerGAEvent(EVENT_CATEGORY_HEADER, EVENT_HEADER_LOGIN);

    if (redirectURL === ROUTES.MYDATA) {
      window.location.replace(`${SA_BASE_URL}${API_ENDPOINTS.LOGIN}?${params}`);
    } else {
      window.location.assign(`${SA_BASE_URL}${API_ENDPOINTS.LOGIN}?${params}`);
    }
  };

  const logout = async () => {
    const appState = generateToken(32);
    const authUrl = `${API_BASE_URL}${API_ENDPOINTS.AUTH_SIGNOUT_URL}?returnUrl=${API_BASE_URL}/`;

    LocalStorage.remove(USER_SESSION_ID);
    sessionStorage.removeItem(RESTRICTED);
    sessionStorage.removeItem(REDIRECT);
    Cookie.remove(REDIRECT_URI);
    const paramsEntity: SignOutEntity = {
      client_id: CLIENT_ID,
      signOutURL: authUrl,
      state: appState,
      acr_values: ACR_VALUES,
    };

    dispatch(actionLogout());
    LocalStorage.remove(USER_DATA_NAMESPACE);
    LocalStorage.remove(USER_SESSION_EXPIRED_TIME);
    LocalStorage.remove(EMAIL_LOGIN_VERIFICATION_RESULT);

    const params = new URLSearchParams({ ...paramsEntity });

    triggerGAEvent(EVENT_CATEGORY_HEADER, EVENT_HEADER_ACTION_LOGOUT);
    if (isDigitalLegacy) {
      const hostname = window.location.hostname;
      const domain = getDomain(hostname);
      Cookie.remove(COOKIE_SC_ATKN, {
        path: "/",
        domain: domain,
      });
      if (userState.userCountryCode === GEOIP_CHINA_COUNTRY_CODE) {
        return window.location.replace(URLS.CHINA_LOCALSITE);
      }
      return;
    }

    window.location.replace(
      `${API_SA_BASE_URL}${API_ENDPOINTS.LOGOUT}?${params}`
    );
  };

  const confirmPassword = (
    services: Array<ServiceEntity>,
    requestType: REQUEST_TYPE
  ) => {
    const arrServices: string[] = [];

    services.forEach((service) => {
      arrServices.push(service.service_code);
    });

    UtilsRequest.setRequestInfo(arrServices, requestType);
    const appState = generateToken(32);

    const redirectConf = `${API_BASE_URL}${ROUTES.MYDATA_HISTORY}`;
    const goBackUrl = `${config.APP_BASE_URL_NO_PARAMS}${ROUTES.MYDATA}`;
    const paramsEntity: ConfirmPassEntity = {
      locale: layoutState.languageCode,
      countryCode: layoutState.countryCode,
      redirect_uri: redirectConf,
      state: appState,
      goBackURL: goBackUrl,
    };
    //update and make dynamic

    const params = new URLSearchParams({ ...paramsEntity });

    window.location.replace(
      `${API_SA_BASE_URL}${API_ENDPOINTS.CONFIRM_PASS_URL}?${params}`
    );
  };

  const digitalLegacyLogin = (redirectURL?: string) => {
    const appState = generateToken(32);

    // const EVENT_HEADER_LOGIN =
    //   userState.userCountryCode === GEOIP_CHINA_COUNTRY_CODE
    //     ? EVENT_HEADER_ACTION_LOGIN_CHINA
    //     : EVENT_HEADER_ACTION_LOGIN;

    const authUrl = `${API_BASE_URL}${
      API_ENDPOINTS.AUTH_SIGNIN_URL
    }?returnUrl=${API_BASE_URL}${
      redirectURL || ROUTES.MYDATA
    }?success=1&appId=${DL_CLIENT_ID}`;

    LocalStorage.remove(USER_SESSION_EXPIRED_TIME);

    const paramsEntity: SignInEntity = {
      response_type: "code",
      client_id: DL_CLIENT_ID,
      locale: layoutState.languageCode,
      countryCode: layoutState.countryCode,
      redirect_uri: authUrl,
      state: appState,
      goBackURL: API_BASE_URL,
      acr_values: ACR_VALUES,
      acr_key: ACR_KEY,
    };

    const params = new URLSearchParams({ ...paramsEntity });

    //TODO: GA Implem
    //triggerGAEvent(EVENT_CATEGORY_HEADER, EVENT_HEADER_LOGIN);

    window.location.replace(
      `${API_SA_V3_BASE_URL}${API_ENDPOINTS.DL_LOGIN}?${params}`
    );
  };

  return { login, logout, confirmPassword, digitalLegacyLogin };
};

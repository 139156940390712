import "./RightsInfoCarousel.scss";

import React, { useEffect } from "react";
import Carousel from "nuka-carousel/lib/carousel";
import { useWindowSize } from "../../../../../../../hooks/window-hooks";
import {
  SVG_ICONS,
  Svg,
} from "../../../../../../../components/__common/Svg/Svg";
import { Container } from "../../../../../../../components/Grid/Grid";
import { Typography } from "../../../../../../../components/__common/Typography/Typography";
import { Button } from "../../../../../../../components/__common/_controls";
import { Trans, useTranslation } from "react-i18next";
import { Image } from "../../../../../../../components/__common/Image";
import { COUNTRY_FLAGS } from "../../../../../../../components/__common/Image/flagImages";
import { useLayout } from "../../../../../../../hooks/layout-hooks";
import { HubCountry } from "../../../../../../../types/landing-page";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../store";
import { LayoutState } from "../../../../../../../store/reducers/layout-reducer";
import { UserState } from "../../../../../../../store/reducers/user-reducer";
import { useHub } from "../../../../../../../hooks/hub-hooks";
import { ROUTES } from "../../../../../../../configs/routes";
import { UtilsUser } from "../../../../../../../utils/UtilsUser";
import { MyDataState } from "../../../../../../../store/reducers/mydata-reducer";
import { useHistory } from "react-router-dom";
import {
  REDIRECT,
  USER_SELECTED_COUNTRY,
} from "../../../../../../../constants/storage";
import { useLoginOptionHooks } from "../../../../../../../hooks/login-option-hooks";
import { Cookie } from "../../../../../../../services/storage";

interface RightsInfoCarouselProps {
  setModalOpen: (value: boolean) => void;
  hasLocalSite: boolean;
}

const RightsInfoCarousel: React.FC<RightsInfoCarouselProps> = ({
  setModalOpen,
  hasLocalSite,
}) => {
  const { isMobile } = useWindowSize();
  const { userCountryCode } = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const { selectedCountry, isRTL } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const { setSelectedCountry, getUserCountry } = useLayout();

  const prevIconDisabled = isMobile
    ? SVG_ICONS.CHEVRON_HUB_LEFT_MOBILE_DISABLED
    : SVG_ICONS.CHEVRON_HUB_LEFT_DISABLED;

  const prevIconEnabled = isMobile
    ? SVG_ICONS.CHEVRON_HUB_LEFT_MOBILE
    : SVG_ICONS.CHEVRON_HUB_LEFT;

  const nextIconDisabled = isMobile
    ? SVG_ICONS.CHEVRON_HUB_RIGHT_MOBILE_DISABLED
    : SVG_ICONS.CHEVRON_HUB_RIGHT_DISABLED;

  const nextIconEnabled = isMobile
    ? SVG_ICONS.CHEVRON_HUB_RIGHT_MOBILE
    : SVG_ICONS.CHEVRON_HUB_RIGHT;

  //carousel custom left control
  const renderCenterLeftControls = ({ previousSlide, currentSlide }: any) => {
    return (
      <>
        <Svg
          icon={currentSlide === 0 ? prevIconDisabled : prevIconEnabled}
          width={50}
          height={50}
          className="country-carousel__controls__button"
          onClick={previousSlide}
        />
      </>
    );
  };

  //carousel custom right control
  const renderCenterRightControls = ({
    nextSlide,
    currentSlide,
    slideCount,
  }: any) => {
    const lastSlide = slideCount - 1;
    return (
      <Svg
        icon={currentSlide === lastSlide ? nextIconDisabled : nextIconEnabled}
        width={50}
        height={50}
        className="country-carousel__controls__button"
        onClick={nextSlide}
      />
    );
  };

  useEffect(() => {
    if (userCountryCode) {
      const country = getUserCountry();
      setSelectedCountry(country);
    }
  }, [userCountryCode]);
  return (
    <div dir="ltr">
      <Carousel
        disableEdgeSwiping={true}
        dragging={false}
        className="rights-info-carousel"
        defaultControlsConfig={{
          containerClassName: "rights-info-carousel__controls",
          pagingDotsContainerClassName: "rights-info-carousel__dots",
          pagingDotsClassName: "rights-info-carousel__dots__dot",
        }}
        renderCenterLeftControls={renderCenterLeftControls}
        renderCenterRightControls={renderCenterRightControls}
        slideIndex={isRTL ? 1 : 0}
      >
        {!isRTL ? (
          <RightsInfo hasLocalSite={hasLocalSite} />
        ) : (
          <RightsFlagContent
            userCountry={selectedCountry}
            setModalOpen={setModalOpen}
            rightsInfoButton={true}
          />
        )}

        {!isRTL ? (
          <RightsFlagContent
            userCountry={selectedCountry}
            setModalOpen={setModalOpen}
            rightsInfoButton={true}
          />
        ) : (
          <RightsInfo hasLocalSite={hasLocalSite} />
        )}
      </Carousel>
    </div>
  );
};

export default RightsInfoCarousel;

interface RightsInfoProps {
  hasLocalSite: boolean;
}
export const RightsInfo: React.FC<RightsInfoProps> = ({ hasLocalSite }) => {
  const historyHook = useHistory();

  const { history } = useSelector<ApplicationState, MyDataState>(
    (state) => state.myData
  );
  const { t } = useTranslation();

  const { handleGAMyDataRedirectHub, handleGARequestHistoryRedirectHub } =
    useHub();

  const withPreviousRequest =
    UtilsUser.isUserLoggedIn() && history.request_count > 0 && hasLocalSite;

  const { handleLoginBasedOnGeoIp } = useLoginOptionHooks();
  const handleMyDataRedirect = () => {
    if (UtilsUser.isUserLoggedIn()) {
      historyHook.push(
        withPreviousRequest ? ROUTES.MYDATA_HISTORY : ROUTES.MYDATA
      );
    } else {
      sessionStorage.setItem(REDIRECT, ROUTES.MYDATA);
      handleLoginBasedOnGeoIp();
    }
  };

  return (
    <Container className="rights-info-container">
      <Container className="rights-info-container_your-rights">
        <Typography
          variant={withPreviousRequest ? "title1" : "title2"}
          className="rights-info-container_your-rights__title"
        >
          {withPreviousRequest
            ? t("PRIVACY_HUB_PREVREQUESTS_TITLE")
            : t("PRIVACY_HUB_YOURRIGHTS_TITLE")}
        </Typography>
        {withPreviousRequest ? (
          <>
            <Typography
              variant="body1"
              weight="w500"
              className="rights-info-container_your-rights__desc"
            >
              {t("PRIVACY_HUB_PREVREQUESTS_TEXT1")}
            </Typography>
            <Typography
              variant="body1"
              weight="w500"
              className="rights-info-container_your-rights__desc"
            >
              {t("PRIVACY_HUB_PREVREQUESTS_TEXT2")}
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant="body1"
              weight="w500"
              className="rights-info-container_your-rights__desc"
            >
              {t("PRIVACY_HUB_YOURRIGHTS_TEXT1")}
            </Typography>
            <Typography
              variant="body1"
              weight="w500"
              className="rights-info-container_your-rights__desc"
            >
              {t("PRIVACY_HUB_YOURRIGHTS_TEXT2")}
            </Typography>
          </>
        )}
        <Button
          testId="go-to-mydata-btn"
          title={
            withPreviousRequest
              ? t("PRIVACY_HUB_PREVREQUESTS_BTN_REQHISTIRY")
              : t("PRIVACY_HUB_YOURRIGHTS_BTN_MYDATA")
          }
          className="rights-info-container_your-rights__button"
          icon={SVG_ICONS.ARROW_RIGHT}
          iconPosition="right"
          onClick={() => {
            if (withPreviousRequest) {
              handleGARequestHistoryRedirectHub();
            } else {
              handleGAMyDataRedirectHub();
            }
            handleMyDataRedirect();
          }}
          type="content"
        />
      </Container>
    </Container>
  );
};

interface FlagContentProps {
  setModalOpen: (value: boolean) => void;
  userCountry: HubCountry;
  rightsInfoButton?: boolean;
}

/* added component for new rights and protection section
  will now display option for user to change location even user has no localsite
*/
export const RightsFlagContent: React.FC<FlagContentProps> = ({
  userCountry,
  setModalOpen,
}) => {
  const { t } = useTranslation();
  const { isCountryNotAllowed } = useHub();
  const { selectedCountry } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  return (
    <Container className="country-carousel__content rights-flag-container">
      {!isCountryNotAllowed && (
        <Image image={COUNTRY_FLAGS[selectedCountry.code]} width={88} />
      )}
      <Typography
        variant="title2"
        className="country-carousel__content__country"
        weight="wMedium"
      >
        <Trans
          i18nKey={
            !Cookie.get(USER_SELECTED_COUNTRY)
              ? "PRIVACY_HUB_COUNTRY_DISPLAY"
              : "PRIVACY_HUB_COUNTRY_DISPLAY_SELECTED"
          }
          components={{
            span: <span></span>,
          }}
          values={{ "%s": userCountry.name }}
        />
      </Typography>
      <Typography
        variant="title2"
        className="country-carousel__content__text"
        weight="wMedium"
      >
        {t("PRIVACY_HUB_COUNTRY_CONNECT_TEXT")}
      </Typography>
      <Button
        testId="pref-rights-country-btn"
        title={t("PRIVACY_HUB_PREFERRED_COUNTRY_BUTTON")}
        className="country-carousel__content__button"
        onClick={() => setModalOpen(true)}
        icon={SVG_ICONS.ARROW_RIGHT}
        iconPosition="right"
        type="content"
      />
    </Container>
  );
};

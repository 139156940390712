import React, { ReactNode } from "react";
import { FeatureToggleContext } from "./FeatureToggleContext";

type FeatureToggleProps = {
  children: ReactNode;
  disabledFeatures: string[];
};

export const FeatureToggle = ({
  children,
  disabledFeatures,
}: FeatureToggleProps) => {
  return (
    <FeatureToggleContext.Provider value={{ disabledFeatures }}>
      {children}
    </FeatureToggleContext.Provider>
  );
};

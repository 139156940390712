export const RCODES = {
  SUCCESS: 0,
  REDIRECT: 3020000,
  BAD_REQUEST: 4000000,
  INVALID_FIELD_VALUE: 4000001,
  FILE_UPLOAD_ERROR: 4000002,
  EMPTY_FILE_ERROR: 4000004,
  DATA_PAIR_MISMATCHED: 4000005,
  DATA_INCOMPLETE: 4000006,
  INVALID_REQUEST_NUMBER: 4000011,
  INVALID_OTP: 4000067,
  MAX_OTP_RETRY: 4000068,
  GUEST_BANNED_15MINS: 4000071,
  GUEST_BANNED_30MINS: 4000072,
  GUEST_BANNED_1HR: 4000073,
  GUEST_BANNED_4HR: 4000074,
  GUEST_BANNED_1D: 4000075,
  FILE_PASSWORD_NOT_SENT: 4000076,
  USER_EMAIL_NOT_SET: 4000077,
  SA_UNVERIFIED_ERROR: 4000078,
  GK_SERVICE_ALREADY_IN_PROGRESS: 4000079,
  GK_INTERNAL_SERVER_ERROR: 4000080,
  UNAUTHORIZED: 4010000,
  FORBIDDEN_REQUEST: 4030000,
  NOT_FOUND: 4040000,
  INTERNAL_SERVER_ERROR: 5000000,
  DATABASE_ERROR: 5000001,
  DATABASE_ERROR2: 5000002,
};

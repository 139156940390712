import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./Overview.scss";
import { Col, Container, Row } from "../../../components/Grid/Grid";
import { Typography } from "../../../components/__common/Typography/Typography";
import { Svg, SVG_ICONS } from "../../../components/__common/Svg/Svg";
import { Button } from "../../../components/__common/_controls/Button/Button";
import { useWindowSize } from "../../../hooks/window-hooks";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../configs/routes";
import IconStep3 from "../../../assets/images/png/icon_policy_overview_step3-2.png";
import Step1 from "../../../assets/images/png/icon_policy_overview_step1.png";
import Step2 from "../../../assets/images/png/icon_policy_overview_step2.png";
import Step3 from "../../../assets/images/png/icon_policy_overview_step3.png";
import { Image } from "../../../components/__common/Image";
import { TTSButton } from "../../../components/__common/_controls";
import { LayoutState } from "../../../store/reducers/layout-reducer";
import { UserState } from "../../../store/reducers/user-reducer";
import { ApplicationState } from "../../../store";
import { useSelector } from "react-redux";
import { CountriesWithLocalPrivacy } from "../../../data/landing-page";
import { UtilsUser } from "../../../utils/UtilsUser";
import { useLoginOptionHooks } from "../../../hooks/login-option-hooks";
import { REDIRECT } from "../../../constants/storage";
import { useUserHooks } from "../../../hooks/user-hooks";

const PrivacyPolicyOverview: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isTabletPortrait } = useWindowSize();
  const history = useHistory();
  const { userCountryCode } = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const { handleLoginBasedOnGeoIp } = useLoginOptionHooks();
  const [hasLocalSite, setHasLocalSite] = useState<boolean>(false);
  const { isDigitalLegacy } = useUserHooks();
  const [localSite, setLocalSite] = useState<string>("");
  const { selectedCountry } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  useEffect(() => {
    const countryCode = selectedCountry.code
      ? selectedCountry.code
      : userCountryCode;

    const hasCountryLocalSite = CountriesWithLocalPrivacy.some(
      (country) => country.code === countryCode
    );

    if (hasCountryLocalSite) {
      const site = CountriesWithLocalPrivacy.filter(
        (country) => country.code === countryCode
      )[0].link;
      setLocalSite(site !== null ? site : "");
    }

    setHasLocalSite(hasCountryLocalSite);
  }, [selectedCountry, userCountryCode]);

  const handleMyDataRedirectUnsigned = () => {
    if (hasLocalSite) {
      window.open(localSite, "_self");
    } else {
      sessionStorage.setItem(REDIRECT, ROUTES.MYDATA);
      handleLoginBasedOnGeoIp();
    }
  };

  const renderManageDataLink = (link: any) => {
    if (UtilsUser.isUserLoggedIn()) {
      if (hasLocalSite && !isDigitalLegacy) {
        window.open(localSite, "_self");
      } else {
        history.push(link);
      }
    } else {
      handleMyDataRedirectUnsigned();
    }
  };

  return (
    <>
      <div className="background">
        <Container className="overview-top">
          <Typography variant="h2" className="overview-top__title">
            {t("PRIVACY_POLICY_OVERVIEW_PAGE_TITLE")}
            <TTSButton
              value={
                t("PRIVACY_POLICY_OVERVIEW_PAGE_TITLE") +
                ". " +
                t("PRIVACY_POLICY_OVERVIEW_PAGE_DESCRIPTION")
              }
            />
          </Typography>
          <Typography variant="body1" className="overview-top__caption">
            {t("PRIVACY_POLICY_OVERVIEW_PAGE_DESCRIPTION")}
          </Typography>
          <Svg
            className="overview-top__icon"
            icon={SVG_ICONS.POLICY_OVERVIEW_SUMMARY}
          />

          {isMobile || isTabletPortrait ? (
            <div data-testid="mobile-overview" className="overview-top__steps">
              <div className="overview-top__steps__step">
                <div className="overview-top__steps__step__caption">
                  <Typography
                    variant="body2"
                    className="white-text__subText overview-top__steps__step__caption__step-number"
                  >
                    STEP 01
                  </Typography>
                  <Typography
                    variant="title2"
                    className="white-text overview-top__steps__step__caption__title"
                  >
                    {t("PRIVACY_POLICY_SUMMARY_STEP1_TITLE")}
                  </Typography>
                  <Typography variant="body2" className="white-text__subText">
                    {t("PRIVACY_POLICY_SUMMARY_STEP1_TEXT")}
                  </Typography>
                </div>
                <div className="white-text overview-top__steps__arrow">
                  {/* removing the condition isTabletPortrait || isMobile */}
                  {/* since it is always true, due to it's already declared at the top level with the same condition, and the else would never be reach in the code */}
                  <Svg
                    icon={SVG_ICONS.ARROW_DOWN_WHITE}
                    className="overview-top__steps__arrow__icon white-text"
                  />
                </div>
              </div>
              <div className="overview-top__steps__step">
                <div className="overview-top__steps__step__caption caption-2">
                  <Typography
                    variant="body2"
                    className="white-text__subText overview-top__steps__step__caption__step-number"
                  >
                    STEP 02
                  </Typography>
                  <Typography
                    variant="title2"
                    className="white-text overview-top__steps__step__caption__title"
                  >
                    {t("PRIVACY_POLICY_SUMMARY_STEP2_TITLE")}
                  </Typography>
                  <Typography variant="body2" className="white-text__subText">
                    {t("PRIVACY_POLICY_SUMMARY_STEP2_TEXT")}
                  </Typography>
                </div>
                <div className="white-text overview-top__steps__arrow">
                  {/* removing the condition isTabletPortrait || isMobile */}
                  {/* since it is always true, due to it's already declared at the top level with the same condition, and the else would never be reach in the code */}
                  <Svg
                    icon={SVG_ICONS.ARROW_DOWN_WHITE}
                    className="overview-top__steps__arrow__icon white-text"
                  />
                </div>
              </div>
              <div className="overview-top__steps__step">
                <div className="overview-top__steps__step__caption">
                  <Typography
                    variant="body2"
                    className="white-text__subText overview-top__steps__step__caption__step-number"
                  >
                    STEP 03
                  </Typography>
                  <Typography
                    variant="title2"
                    className="white-text overview-top__steps__step__caption__title"
                  >
                    {t("PRIVACY_POLICY_SUMMARY_STEP3_TITLE")}
                  </Typography>
                  <Typography variant="body2" className="white-text__subText">
                    {t("PRIVACY_POLICY_SUMMARY_STEP3_TEXT")}
                  </Typography>
                </div>
              </div>
            </div>
          ) : (
            <div className="table">
              <div className="steps-col-container">
                <Typography
                  variant="body2"
                  className="white-text__subText overview-top__steps__step__caption__step-number"
                >
                  STEP 01
                </Typography>
                <Typography
                  variant="title2"
                  className="white-text overview-top__steps__step__caption__title"
                >
                  {t("PRIVACY_POLICY_SUMMARY_STEP1_TITLE")}
                </Typography>
                <Typography variant="body2" className="white-text__subText">
                  {t("PRIVACY_POLICY_SUMMARY_STEP1_TEXT")}
                </Typography>
              </div>
              <div className="arrow-container">
                <Svg
                  icon={SVG_ICONS.ARROW_RIGHT_WHITE}
                  className="overview-top__steps__arrow__icon white-text arrow-icon"
                  width={16}
                  height={32}
                />
              </div>
              <div className="steps-col-container">
                <Typography
                  variant="body2"
                  className="white-text__subText overview-top__steps__step__caption__step-number"
                >
                  STEP 02
                </Typography>
                <Typography
                  variant="title2"
                  className="white-text overview-top__steps__step__caption__title"
                >
                  {t("PRIVACY_POLICY_SUMMARY_STEP2_TITLE")}
                </Typography>
                <Typography variant="body2" className="white-text__subText">
                  {t("PRIVACY_POLICY_SUMMARY_STEP2_TEXT")}
                </Typography>
              </div>
              <div className="arrow-container">
                <Svg
                  icon={SVG_ICONS.ARROW_RIGHT_WHITE}
                  className="overview-top__steps__arrow__icon white-text arrow-icon"
                  width={16}
                  height={32}
                />
              </div>
              <div className="steps-col-container">
                <Typography
                  variant="body2"
                  className="white-text__subText overview-top__steps__step__caption__step-number"
                >
                  STEP 03
                </Typography>
                <Typography
                  variant="title2"
                  className="white-text overview-top__steps__step__caption__title"
                >
                  {t("PRIVACY_POLICY_SUMMARY_STEP3_TITLE")}
                </Typography>
                <Typography variant="body2" className="white-text__subText">
                  {t("PRIVACY_POLICY_SUMMARY_STEP3_TEXT")}
                </Typography>
              </div>
            </div>
          )}
        </Container>
      </div>

      <div className="overview-step1">
        <Container className="overview-step1__container">
          <Image image={Step1} className="steps-tag" />
          <Typography variant="h3" className="overview-step1__container__title">
            {t("PRIVACY_POLICY_STEP1_TITLE")}
            <TTSButton value={t("PRIVACY_POLICY_STEP1_TITLE")} />
          </Typography>
          <Row className="overview-step1__container__row">
            <Col
              sm={12}
              md={6}
              lg={4}
              className="overview-step1__container__col"
            >
              <div className="overview-step1__container__card">
                <Svg
                  icon={SVG_ICONS.POLICY_OVERVIEW_STEP1_1}
                  className="overview-step1__container__card__icon"
                />
                <div className="overview-step1__container__card__desc">
                  <Typography
                    className="overview-step1__container__card__title"
                    variant="title2"
                  >
                    {t("PRIVACY_POLICY_STEP1_ESSENTIAL_INFO_TITLE")}
                  </Typography>
                  <Typography
                    className="overview-step1__container__card__caption"
                    variant="body1"
                  >
                    {t("PRIVACY_POLICY_STEP1_ESSENTIAL_INFO_TEXT")}
                  </Typography>
                </div>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              className="overview-step1__container__col"
            >
              <div className="overview-step1__container__card">
                <Svg
                  icon={SVG_ICONS.POLICY_OVERVIEW_STEP1_2}
                  className="overview-step1__container__card__icon"
                />
                <div className="overview-step1__container__card__desc">
                  <Typography
                    className="overview-step1__container__card__title"
                    variant="title2"
                  >
                    {t("PRIVACY_POLICY_STEP1_SERVICE_INFO_TITLE")}
                  </Typography>
                  <Typography
                    className="overview-step1__container__card__caption"
                    variant="body1"
                  >
                    {t("PRIVACY_POLICY_STEP1_SERVICE_INFO_TEXT")}
                  </Typography>
                </div>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              className="overview-step1__container__col"
            >
              <div className="overview-step1__container__card">
                <Svg
                  icon={SVG_ICONS.POLICY_OVERVIEW_STEP1_3}
                  className="overview-step1__container__card__icon"
                />
                <div className="overview-step1__container__card__desc">
                  <Typography
                    className="overview-step1__container__card__title"
                    variant="title2"
                  >
                    {t("PRIVACY_POLICY_STEP1_MEMBERSHIP_TITLE")}
                  </Typography>
                  <Typography
                    className="overview-step1__container__card__caption"
                    variant="body1"
                  >
                    {t("PRIVACY_POLICY_STEP1_MEMBERSHIP_TEXT")}
                  </Typography>
                </div>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              className="overview-step1__container__col"
            >
              <div className="overview-step1__container__card">
                <Svg
                  icon={SVG_ICONS.POLICY_OVERVIEW_STEP1_4}
                  className="overview-step1__container__card__icon"
                />
                <div className="overview-step1__container__card__desc">
                  <Typography
                    className="overview-step1__container__card__title"
                    variant="title2"
                  >
                    {t("PRIVACY_POLICY_STEP1_OPTIMAL_CONTENT_TITLE")}
                  </Typography>
                  <Typography
                    className="overview-step1__container__card__caption"
                    variant="body1"
                  >
                    {t("PRIVACY_POLICY_STEP1_OPTIMAL_CONTENT_TEXT")}
                  </Typography>
                </div>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              className="overview-step1__container__col"
            >
              <div className="overview-step1__container__card">
                <Svg
                  icon={SVG_ICONS.POLICY_OVERVIEW_STEP1_5}
                  className="overview-step1__container__card__icon"
                />
                <div className="overview-step1__container__card__desc">
                  <Typography
                    className="overview-step1__container__card__title"
                    variant="title2"
                  >
                    {t("PRIVACY_POLICY_STEP1_NOTIFICATIONS_TITLE")}
                  </Typography>
                  <Typography
                    className="overview-step1__container__card__caption"
                    variant="body1"
                  >
                    {t("PRIVACY_POLICY_STEP1_NOTIFICATIONS_TEXT")}
                  </Typography>
                </div>
              </div>
            </Col>
            <Col
              md={4}
              sm={12}
              className="overview-step1__container__card transparent"
            >
              <div className="overview-step1__container__card transparent" />
            </Col>
          </Row>
          <Svg
            className="overview-step1__container__table"
            icon={SVG_ICONS.POLICY_OVERVIEW_STEP1_TABLE}
          />
        </Container>
      </div>

      <div className="background">
        <Container className="overview-step2">
          <Image image={Step2} className="steps-tag" />
          <Typography variant="h3" className="overview-step2__title">
            {t("PRIVACY_POLICY_STEP2_TITLE")}
            <TTSButton value={t("PRIVACY_POLICY_STEP2_TITLE")} />
          </Typography>
          <Row className="overview-step2__row">
            <Col sm={12} md={6} lg={6} className="overview-step2__section">
              <div className="overview-step2__section__card">
                <Svg
                  icon={SVG_ICONS.POLICY_OVERVIEW_STEP2_1}
                  className="overview-step2__section__icon"
                />
              </div>
              <div className="overview-step2__section__caption">
                <Typography
                  className="overview-step2__section__caption__title"
                  variant="title2"
                >
                  {t("PRIVACY_POLICY_STEP2_THIRDPARTY_TITLE")}
                </Typography>
                <Typography
                  className="overview-step2__section__caption__desc"
                  variant="body1"
                >
                  {t("PRIVACY_POLICY_STEP2_THIRDPARTY_TEXT")}
                </Typography>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} className="overview-step2__section">
              <div className="overview-step2__section__card">
                <Svg
                  icon={SVG_ICONS.POLICY_OVERVIEW_STEP2_2}
                  className="overview-step2__section__icon"
                />
              </div>
              <div className="overview-step2__section__caption">
                <Typography
                  className="overview-step2__section__caption__title"
                  variant="title2"
                >
                  {t("PRIVACY_POLICY_STEP2_PARTNERS_TITLE")}
                </Typography>
                <Typography
                  className="overview-step2__section__caption__desc"
                  variant="body1"
                >
                  {t("PRIVACY_POLICY_STEP2_PARTNERS_TEXT")}
                </Typography>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="background">
        <Container className="overview-step3">
          <Image image={Step3} className="steps-tag" />
          <Typography variant="h3" className="overview-step3__title">
            {t("PRIVACY_POLICY_STEP3_TITLE")}
            <TTSButton value={t("PRIVACY_POLICY_STEP3_TITLE")} />
          </Typography>
          <Row className="overview-step3__row">
            <Col sm={12} md={12} lg={12} className="overview-step3__section">
              <div className="overview-step3__section__card">
                <Svg
                  icon={SVG_ICONS.POLICY_OVERVIEW_STEP3_1}
                  className="overview-step3__section__card__icon"
                />
              </div>
              <div className="overview-step3__section__caption right-caption">
                <Typography
                  className="overview-step3__section__caption__title"
                  variant="title2"
                >
                  {t("PRIVACY_POLICY_STEP3_PARTNERS_TITLE")}
                </Typography>
                <Typography
                  className="overview-step3__section__caption__desc"
                  variant="body1"
                >
                  {t("PRIVACY_POLICY_STEP3_PARTNERS_TEXT")}
                </Typography>
              </div>
            </Col>
          </Row>
          <Row className="overview-step3__row">
            <Col
              sm={12}
              md={12}
              lg={12}
              className="overview-step3__section reverse-caption"
            >
              <div className="overview-step3__section__caption left-caption">
                <Typography
                  className="overview-step3__section__caption__title"
                  variant="title2"
                >
                  {t("PRIVACY_POLICY_STEP3_DESTRUCTION_TITLE")}
                </Typography>
                <Typography
                  className="overview-step3__section__caption__desc"
                  variant="body1"
                >
                  {t("PRIVACY_POLICY_STEP3_DESTRUCTION_TEXT")}
                </Typography>
              </div>
              <div className="overview-step3__section__card">
                <Image
                  image={IconStep3}
                  className="overview-step3__section__card__icon"
                />
              </div>
            </Col>
          </Row>
          <Row className="overview-step3__row">
            <Col sm={12} md={12} lg={12} className="overview-step3__section">
              <div className="overview-step3__section__card">
                <Svg
                  icon={SVG_ICONS.POLICY_OVERVIEW_STEP3_3}
                  className="overview-step3__section__card__icon"
                />
              </div>
              <div className="overview-step3__section__caption right-caption">
                <Typography
                  className="overview-step3__section__caption__title"
                  variant="title2"
                >
                  {t("PRIVACY_POLICY_STEP3_DESTRUCTION_METHOD_TITLE")}
                </Typography>
                <Typography
                  className="overview-step3__section__caption__desc"
                  variant="body1"
                >
                  {t("PRIVACY_POLICY_STEP3_DESTRUCTION_METHOD_TEXT")}
                </Typography>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="overview-bottom">
        <Container className="overview-bottom__container">
          {isMobile ? (
            <Svg
              icon={SVG_ICONS.POLICY_OVERVIEW_BOTTOM_1_MOBILE}
              width={62}
              height={136}
              className="overview-bottom__icon-left"
            />
          ) : (
            <Svg
              icon={SVG_ICONS.POLICY_OVERVIEW_BOTTOM_1}
              width={270}
              height={207}
              className="overview-bottom__icon-left"
            />
          )}
          <div className="overview-bottom__content">
            <Typography
              className="overview-bottom__content__title white-text"
              variant="title2"
            >
              {t("PRIVACY_POLICY_OVERVIEW_MANAGE_PERSONAL_DATA")}
            </Typography>
            <Typography
              className="overview-bottom__content__caption white-text"
              variant="body1"
            >
              {t("PRIVACY_POLICY_OVERVIEW_MANAGE_PERSONAL_DATA_TEXT")}
            </Typography>
            <span className="overview-bottom__button-container">
              <Button
                testId="mydata-redirect-button"
                className="overview-bottom__button"
                title={t("PRIVACY_POLICY_OVERVIEW_MANAGE_PERSONAL_DATA_BUTTON")}
                type="content"
                onClick={() => renderManageDataLink(ROUTES.MYDATA)}
              />
            </span>
          </div>
          {isMobile ? (
            <Svg
              icon={SVG_ICONS.POLICY_OVERVIEW_BOTTOM_2_MOBILE}
              width={62}
              height={136}
              className="overview-bottom__icon-right"
            />
          ) : (
            <Svg
              icon={SVG_ICONS.POLICY_OVERVIEW_BOTTOM_2}
              width={270}
              height={207}
              className="overview-bottom__icon-right"
            />
          )}
        </Container>
      </div>
    </>
  );
};

export default PrivacyPolicyOverview;
